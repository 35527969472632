<template>
    <div class="fan-analysis">
        <div class="analysis-left">
            <div class="gender-distribution">
                <span class="title">性别分布</span>
                <div class="distribution-chart">
                    <div class="chart-icon">
                        <i class="iconfont">&#xe6a6;</i>
                        <i class="iconfont">&#xe6a8;</i>
                    </div>
                    <div class="chart-content">
                        <div class="man" :style="{width: fansData.man+'%'}"></div>
                        <div class="woman"></div>
                    </div>
                    <div class="legend">
                        <div class="legend-man">
                            <span>男</span>
                            <span>{{fansData.man}}%</span>
                        </div>
                        <div class="legend-woman">
                            <span>女</span>
                            <span>{{fansData.woman}}%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="age-distribution">
                <span class="title">年龄分布</span>
                <div id="ageDistribution" style="width: 100%;height: 275px;position: relative"></div>
            </div>
        </div>
        <div class="analysis-right">
            <el-scrollbar style="height: 100%;">
                <div class="tab-area">
                    <span class="tab-area-title">地区分布</span>
                    <span>
                    <el-radio-group v-model="radio" size="small" @change="getListByCh">
                        <el-radio-button label="0">省份</el-radio-button>
                        <el-radio-button label="1">城市</el-radio-button>
                    </el-radio-group>
                </span>
                </div>
                <div class="area-distribution">
                    <div class="title">
                        <span>名称</span>
                        <span>占比</span>
                    </div>
                    <div class="content">
                        <div v-if="areaData.length > 0">
                            <div class="area-item" v-for="item in areaData">
                                <span class="area-name">{{item.title}}</span>
                                <el-progress :percentage="item.rate" :show-text="false":stroke-width="12"></el-progress>
                                <span class="percentage">{{item.rate}}%</span>
                            </div>
                        </div>
                        <div v-else class="null-data-area">暂无数据</div>
                    </div>
                </div>
            </el-scrollbar>

        </div>
    </div>
</template>

<script>
    import {fansPicture} from "@/utils/apis";

    export default {
        name: "EcFanDataModule",
        data() {
            return {
                areaData: [],
                fansData:{},
                radio: 0,
                ageData: null,
                cityData: [],
                provinceData: [],
                // man: 0,
                // woman: 0,
            }
        },
        mounted() {
            window.onresize = () => {
                this.ageDistributionFun(this.ageData);
                this.ageDistribution.resize();
            };
            let douyinuser_id = this.$route.query.douyinuser_id;
            this.sellGoodsPeopleDetailFun(douyinuser_id)
            this.ageDistribution = this.$echarts.init(document.getElementById('ageDistribution'));
        },
        methods: {
            sellGoodsPeopleDetailFun(douyinuser_id) {
                // 带货达人详情
                let param = {
                    douyinuser_id: douyinuser_id
                };
                fansPicture(douyinuser_id).then(res => {
                    if (res.code === 200) {
                        this.fansData = {
                            man: res.data.gender[0].rate,
                            woman: res.data.gender[1].rate
                        }
                        this.ageData = res.data.age
                        this.ageDistributionFun(this.ageData)
                        this.cityData = res.data.city
                        this.provinceData = res.data.province
                        this.areaData = this.provinceData
                    }
                }).catch(err => {
                    console.log("异常", err)
                })
                //
                // return new Promise((resolve, reject) => {
                //     this.$httpStudent.axiosGetBy(this.$api.sellGoodsPeopleDetail, param, res => {
                //             if (res.code == 200) {
                //                 resolve(res.data.author_id);
                //             } else {
                //                 this.$message({
                //                     type: "error",
                //                     message: res.msg,
                //                     duration: 1000
                //                 });
                //             }
                //         },
                //         err => {
                //             reject(err);
                //         });
                // });
            },
            // hotPeopleFansDataFun(author_id) {
            //     this.$httpStudent.axiosGetBy(this.$api.hotPeopleFansData, {author_id: author_id}, res => {
            //             if (res.code == 200) {
            //                 // 男女比例数
            //                 this.fansData["man"] = Number(res.data.fans_fenbu.gender.male.toFixed(2));
            //                 this.fansData["woman"] = Number(res.data.fans_fenbu.gender.female.toFixed(2));
            //                 // 年龄分布
            //                 this.fansData["age"] = res.data.fans_fenbu.age;
            //                 this.ageDistributionFun( this.fansData.age )
            //                 //  城市分布
            //                 this.fansData["city"] = res.data.fans_fenbu.city;
            //                 //  省份分布
            //                 this.fansData["province"] = res.data.fans_fenbu.province;
            //                 this.areaDataFun(this.fansData.province);
            //             } else {
            //                 this.$message({
            //                     type: "error",
            //                     message: res.msg,
            //                     duration: 1000
            //                 });
            //                 this.ageDistributionFun([]);
            //             }
            //         },
            //         err => {
            //             this.ageDistributionFun([]);
            //             console.error(err);
            //         }
            //     );
            // },
            ageDistributionFun(array) {
                //年龄段数据处理
                let ageArray = [];
                let dataArray = [];
                array.forEach((item) => {
                    ageArray.push(item.title)
                    dataArray.push(item.rate)
                })
                this.ageDistribution.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        }
                    },
                    color: {
                        type: 'linear',
                        x: 0.5,
                        y: 0.5,
                        r: 0.5,
                        colorStops: [{
                            offset: 1, color: '#25C495' // 0% 处的颜色
                        }, {
                            offset: 0, color: '#039167' // 100% 处的颜色
                        }],
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: ageArray,
                            axisTick: {
                                alignWithLabel: true
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#ccc"
                                }
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            axisLine:{       //y轴
                                show:false
                            },
                            axisTick:{       //y轴刻度线
                                show:false
                            },
                            splitLine: {     //网格线
                                show: false
                            },
                            axisLabel:{
                                formatter:function () {
                                    return""
                                }
                            }
                        }
                    ],
                    series: [
                        {
                            name: '',
                            type: 'bar',
                            barWidth: '30%',
                            itemStyle: {        //上方显示数值
                                normal: {
                                    label: {
                                        show: true, //开启显示
                                        position: 'top', //在上方显示
                                        textStyle: { //数值样式
                                            color: '#ccc',
                                            fontSize: 16
                                        }
                                    }
                                }
                            },
                            data: dataArray
                        }
                    ]
                },true);
                let isZero = true ;//数据是否为0
                if(array.length > 0){
                    for (let i in array){
                        if(array[i].rate > 0){
                            isZero = false;
                            break;
                        }
                    }
                }if(isZero){
                    let div1 = document.createElement("div");
                    div1.innerHTML = "暂无数据";
                    div1.style.position = "absolute";
                    div1.style.top = "100px";
                    div1.style.left = "50%";
                    document.querySelector("#ageDistribution").appendChild(div1)
                }
            },
            areaDataFun(area) {
                this.areaData = [];
                if (area.length>0){
                    this.areaData = [];
                    let num = area[0].rate;
                    //地区数据处理
                    for (let i=0;i<10;i++) {
                        let obj = {
                            areaName:area[i].title,
                            value:Number(area[i].rate.toFixed(2)),
                            percentage:Number((area[i].rate/ num *100).toFixed(2)),

                        };
                        this.areaData.push(obj);
                    }
                }

            },
            //省份/城市
            getListByCh() {
                if (this.radio == 0) {
                    this.areaData = this.provinceData
                } else {
                    this.areaData = this.cityData
                }
            },
        },

    }
</script>

<style scoped lang="scss">
    .fan-analysis {
        display: flex;
        justify-content: space-between;
        height: 100%;
        padding-bottom: 20px;

        .analysis-left {
            display: flex;
            flex-direction: column;
            width: 50%;
            height: 100%;
            border-right: 1px solid #EAEAEA;
            padding: 0 20px;

            .gender-distribution {
                display: flex;
                flex-direction: column;
                margin-bottom: 50px;

                .title {
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                }

                .distribution-chart {
                    .chart-icon {
                        display: flex;
                        justify-content: space-between;
                        color: #34aa7f;
                        margin-top: 10px;

                        & i {
                            font-size: 24px;
                        }
                    }

                    .chart-content {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        height: 20px;
                        margin-top: 10px;

                        .man {
                            background-color: #34aa7f;
                        }

                        .woman {
                            flex: 1;
                            background-color: #46dbab;
                        }
                    }

                    .legend {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        display: flex;
                        justify-content: space-between;
                        margin-top: 20px;

                        .legend-man {
                            display: flex;
                            flex-direction: column;
                        }

                        .legend-woman {
                            display: flex;
                            flex-direction: column;
                        }
                    }

                }
            }
            .age-distribution {
                .title {
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                }
            }
        }

        .analysis-right {
            &::v-deep .el-scrollbar {
                .el-scrollbar__wrap {
                  overflow: auto;
                }
            }
            display: flex;
            flex-direction: column;
            width: 50%;
            height: calc(100vh - 193px);
            padding: 0 20px;
            overflow: auto;
            .tab-area{
                display: flex;
                justify-content: space-between;
                align-items: center;
                ::v-deep.el-radio-button__orig-radio:checked+.el-radio-button__inner{
                    color: #FFF;
                    background-color: #2338E6;
                    border-color: #2338E6;
                    box-shadow: -1px 0 0 0 #2338E6;
                }
            }
            .title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;
                padding-right: 5px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
            }

            .content {
                flex: 1;
                overflow: auto;
                padding-right: 5px;
            }

            .area-distribution {
                flex: 1;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                height: calc(100% - 66px);

                .area-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 15px;
                    .area-name {
                        width: 50px;
                        white-space: nowrap;
                        overflow: hidden;
                    }

                    ::v-deep .el-progress {
                        width: 80%;
                        padding: 0 10px;
                    }

                    ::v-deep .el-progress-bar__inner {
                        background: linear-gradient(45deg, #ffa72a, #f8b95f 98%);
                    }
                }
            }
        }
        .null-data-area{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 200px;
        }
    }
</style>